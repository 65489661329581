.footer-container {
  border-top: solid 1px #eaeaea;
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      list-style-type: none;
    }
  }
  .footer {
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    background: #fff;
    padding: 0 120px;
    justify-content: space-between;
    @media (max-width: 1440px) {
      padding: 0 24px;
    }
    @media (max-width: 575px) {
      padding: 0 12px;
    }
    &-left,
    &-reight {
      padding: 12px 0;
      @media (max-width: 1024px) {
        padding: 20px 0 12px 0;
      }
      @media (max-width: 664px) {
        padding: 20px 0 4px 0;
      }
    }
    &-left {
      align-items: center;
      font-size: 0;
      @media (max-width: 664px) {
        justify-content: space-between;
        width: 100%;
      }
      &-chtLogo {
        margin-right: 32px;
        font-size: 0;
        @media (max-width: 664px) {
          width: 80px;
          img {
            width: 100%;
          }
        }
      }
      &-Logo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        ul {
          li {
            @media (max-width: 664px) {
              width: 65px;
              height: 32px;
            }
            a {
              padding: 2px 24px;
              @media (max-width: 664px) {
                padding: 0 12px;
                display: block;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    &-right {
      &-link {
        display: flex;
        align-items: center;
        ul {
          li {
            padding: 10px 0;
            @media (max-width: 1640px) {
              padding: 7px 0 10px 0;
              &:first-child {
                a {
                  padding-left: 0;
                }
              }
            }
            @media (max-width: 664px) {
              padding: 4px 0 8px 0;
            }
            &:last-child {
              a {
                border-right: none;
              }
            }
            a {
              border-right: solid 1px #999;
              padding: 0px 16px;
              display: block;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              color: #333;
              @media (max-width: 664px) {
                font-size: 12px;
                padding: 0px 9px;
                line-height: 20px;
              }
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      &-copyright {
        align-items: center;
        margin-left: 12px;
        @media (max-width: 1024px) {
          margin-bottom: 0px;
        }
        @media (max-width: 991px) {
          margin-left: 0px;
          margin-bottom: 10px;
        }
        @media (max-width: 664px) {
          text-align: center;
        }
        p {
          margin-bottom: 3.5px;
          color: #666;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 120% */
          @media (max-width: 575px) {
            zoom: 0.66;
          }
        }
      }
    }
  }
}
